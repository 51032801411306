<template>
  <k-page>
    <h3 class="my-3 font-weight-light text-h4">Política de Privacidade</h3>
    <p>
      A sua privacidade é importante para nós. É política da
      {{ data.COMPANY_NAME }}
      respeitar a sua privacidade em relação a qualquer informação sua que
      possamos coletar em nosso sistema.
    </p>

    <p>
      Solicitamos informações pessoais apenas quando realmente precisamos delas
      para fornecer o nosso serviço. Fazemos por meios justos e legais de acordo
      com a Lei Geral de Proteção de Dados (nº 13.709/2018). Também informamos
      por que estamos coletando e como será usado.
    </p>

    <p>
      Apenas retemos as informações coletadas pelo tempo necessário para
      fornecer o serviço solicitado. Quando armazenamos dados, os protegemos
      dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos,
      bem como acesso, divulgação, cópia ou uso não autorizado.
    </p>

    <p>
      Não compartilhamos dados identificáveis publicamente ou com terceiros,
      exceto quando exigido por lei, também de acordo com a Lei Geral de
      Proteção de Dados (nº 13.709/2018).
    </p>

    <p>
      O nosso site pode ter links para sites externos que não são operados por
      nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas
      desses sites e não podemos aceitar responsabilidade por suas respectivas
      políticas de privacidade.
    </p>

    <p>
      Você é livre para recusar a nossa solicitação de informações pessoais,
      entendendo que talvez não possamos fornecer os serviços desejados.
    </p>

    <p>
      Ao acessar o sistema será considerado como a aceitação de nossas práticas
      em torno de privacidade e informações pessoais. Se você tiver alguma
      dúvida sobre como lidamos com dados do usuário e informações pessoais,
      entre em contato conosco.
    </p>

    <h3 class="my-3 font-weight-light text-h4">
      Trabalhamos com os seguintes dados
    </h3>

    <h4 class="my-3 font-weight-light text-h5">Dados relacionados à conta</h4>

    <p>
      Usamos tokens de identificação que permitem que você autentique suas ações
      dentro do sistema. Esses tokens são excluídos quando você sair do sistema,
      porém, em alguns casos, eles poderão permanecer posteriormente para
      lembrar as preferências do seu site ao sair.
    </p>

    <h4 class="my-3 font-weight-light text-h5">Dados relacionados ao login</h4>

    <p>
      O acesso ao sistema será realizado tanto mediante informação dos dados de
      login previamente cadastrados. Somente usuários com e-mail e senha
      cadastradas terão acesso à parte administrativa gerenciada por esta
      aplicação.
    </p>

    <h4 class="my-3 font-weight-light text-h5">Serviço de Terceiros</h4>

    <p>
      Em alguns casos especiais, também usamos cookies fornecidos por terceiros
      confiáveis. Para mais informações sobre as suas respectivas políticas de
      privacidade, consulte os links abaixo.
    </p>

    <p class="font-weight-bold">Youtube</p>
    <p>
      https://www.youtube.com/intl/pt-BR/about/policies/#community-guidelines
    </p>

    <p class="font-weight-bold">ReCaptcha</p>

    <p>https://policies.google.com/privacy?hl=pt-BR</p>

    <h3 class="my-3 font-weight-light text-h4">Mais informações</h3>

    <p>
      Quaisquer dúvidas sobre as informações existentes nesta política podem ser
      resolvidas entrando em contato com o e-mail:
      {{ data.COMPANY_EMAIL }}.
    </p>

    <p class="caption">
      Esta política será usada a partir de {{ data.publishDate }}.
    </p>
  </k-page>
</template>

<script>
import KPage from "@/components/KPage";
export default {
  components: {
    KPage
  },

  computed: {
    data() {
      return {
        publishDate: "01/03/2022",
        ...this.$store.getters["settings/values"]
      };
    }
  }
};
</script>

<style>
</style>
