<template>
  <v-container class="fill-height align-content-center d-flex">
    <v-row align="center" justify="center">
      <!-- Ícone -->
      <v-col class="d-flex align-center justify-center py-0">
        <v-icon size="60">mdi-alert</v-icon>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col class="d-flex align-center justify-center py-0">
        <h1 class="font-weight-light text-center">
          Erro {{ errors.code }} - {{ errors.title }}
        </h1>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="mx-0">
      <v-col class="d-flex align-center justify-center py-0 px-4">
        <p class="title font-weight-light text-center">
          {{ errors.description }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Error",
  computed: {
    errors() {
      const data = {
        400: {
          title: "Requisição Inválida",
          description: "Existem erros não tratados nesta página"
        },
        401: {
          title: "Não Autorizado",
          description: "O acesso a esta página não foi autorizado"
        },
        403: {
          title: "Proibido",
          description: "Esta página não pode ser acessada"
        },
        404: {
          title: "Página Não Encontrada",
          description:
            "A página que você acessou não existe ou pode ter sido movida para outro endereço."
        }
      };

      const code = this.getCode();
      // console.log("code", code);

      return {
        code,
        ...data[code]
      };
    }
  },
  methods: {
    /**
     * Pega o código que está na url, como parâmetro.
     * Se não existir código, retorne 404
     */
    getCode() {
      const url = this.$route.fullPath;
      const matches = /[0-9]{3}$/.exec(url);
      if (matches) {
        return matches[0];
      } else {
        return 404;
      }
    }
  },
  mounted() {
    // console.log(this.$route);
  }
};
</script>

<style>
</style>
